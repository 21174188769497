.ItkJkIjsPaVsiFF6S9B5t {
  background-color: #d6d8d7;
  color: #757575;
}
div[id='agent_search'] {
  border-top-left-radius: 8px;
}
div[id='realtor_directory'] {
  border-top-right-radius: 8px;
}
.tab::after {
  height: 4px !important;
  width: 100% !important;
  text-align: center !important;
}
._19BbUOcUo6e3AoviLzbqN7 {
  background: white;
  color: #333333 !important;
  border-radius: 8px 8px 0 0;
}
button:focus {
  outline: none;
}
.tabs-container .tab-list .tab::after {
  background-color: #d6d8d7;
}
.tabs-container .tab-list {
  overflow-x: visible;
}
.tab {
  color: inherit !important;
  font-weight: bold;
  border-bottom: none;
  margin: 0 16px !important;
}
.tab[aria-selected='false']:hover::after {
  background-color: transparent !important;
}
.tab span {
  color: inherit;
}
.tabs-container {
  background-color: #d6d8d7;
  width: 241px;
  border-radius: 8px 8px 0 0;
}
