/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
.react-autosuggest__section-title {
  margin-bottom: 3px;
  padding: 4px 16px 4px 16px;
  color: #757575;
}
.react-autosuggest__section-container {
  margin-bottom: 5px;
  border-top: 1px solid #e0e0e0;
}
.react-autosuggest__section-container .react-autosuggest__suggestion {
  font-size: 1.1em;
  background: white;
}
@media (max-width: 668px) {
  .react-autosuggest__section-container--first {
    border-top: none;
  }
}
.react-autosuggest__suggestion {
  cursor: pointer;
  line-height: 2.2em;
  overflow: hidden;
  padding: 4px 16px 4px 16px;
}
.react-autosuggest__suggestion:hover {
  border: #e0e0e0;
  background: #e0e0e0;
  color: #4c4c4c;
}
.react-autosuggest__suggestion--highlighted {
  background: #d6d6d6;
}
.react-autosuggest__suggestions-container--open {
  background: white;
  border: 1px solid #d6d6d6;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  z-index: 1000;
  top: 40px;
  border-top: 0 solid;
  text-align: left;
  transition: visibility 0.6s ease-in-out;
  transition-delay: 1s;
}
@media (max-width: 668px) {
  .react-autosuggest__suggestions-container--open {
    max-height: 100%;
    border: none;
    position: fixed;
    top: 112px;
    left: 0;
    padding: 0 20px 20px 20px;
    height: 100%;
    box-shadow: none;
  }
}
.highlight {
  font-weight: 600;
  color: #333333;
}
[id='home-page-agent-search'] .react-autosuggest__suggestions-container--open [id='home-page-realtor-directory'] .react-autosuggest__suggestions-container--open {
  max-height: 415px;
  overflow: auto;
}
@media (max-width: 668px) {
  [id='home-page-agent-search'] .react-autosuggest__suggestions-container--open [id='home-page-realtor-directory'] .react-autosuggest__suggestions-container--open {
    max-height: 100%;
    border: none;
    position: fixed;
    top: 108px;
    left: 0;
    padding: 0 20px 20px 20px;
    height: 100%;
    box-shadow: none;
  }
}
@media (max-width: 668px) {
  [id='home-page-realtor-directory'] .react-autosuggest__suggestions-container--open {
    top: 164px;
  }
}
.closeIcon {
  right: 24px;
  top: 12px;
  width: 10px;
  height: 42px;
  font-size: 16px;
}
@media (max-width: 668px) {
  .closeIcon {
    z-index: 2020;
  }
}
.disableHoverFocus {
  border-color: #adadad;
}
.disableHoverFocus:focus {
  border-color: #adadad !important;
}
.disableHoverFocus:hover {
  border-color: #adadad !important;
}
