/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
@media (max-width: 668px) {
  ._3iAY2sPFByE14hg32Xhj5n {
    position: absolute;
    width: calc(100vw - 48px);
    z-index: 2003;
    transition: all 0.5s ease-in;
    visibility: hidden;
    opacity: 0;
  }
}
._2NjkCONHHIfsOLeZySV5-3 {
  width: 192px;
  text-align: left;
}
@media (max-width: 668px) {
  ._2NjkCONHHIfsOLeZySV5-3 {
    position: relative;
    top: -256px;
    width: 100%;
    z-index: 2003;
  }
}
._3iAY2sPFByE14hg32Xhj5n._3d1VG1aQBz2Pj4PY0-Vdlh {
  visibility: visible;
  opacity: 1;
}
@media (min-width: 669px) {
  ._3iAY2sPFByE14hg32Xhj5n._3d1VG1aQBz2Pj4PY0-Vdlh {
    display: none;
  }
}
._2cyfR7atNqttQ4MHqIKdl9 {
  display: block;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 668px) {
  ._2cyfR7atNqttQ4MHqIKdl9 {
    max-width: 100%;
  }
}
.EckoFFE1BmTwTtaAlgDJV {
  top: auto;
  position: absolute;
  width: 192px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background-color: white;
}
@media (max-width: 668px) {
  .EckoFFE1BmTwTtaAlgDJV {
    top: -216px;
    width: 100%;
  }
}
._3qJhipKYcpESYAlort1k_m {
  white-space: pre-wrap;
  text-align: left;
  cursor: pointer;
  padding: 10px;
  margin: 0;
  line-height: 2rem;
}
._3qJhipKYcpESYAlort1k_m:hover {
  background-color: #eaeaea;
}
._3qJhipKYcpESYAlort1k_m:first-child {
  margin-top: 6px;
}
._1Hbf3rKYo03BEMC1bPgdM3 {
  background-color: #eaeaea;
}
.NR9qNJDKo9_PdhmEV1Hvz {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 24px;
}
