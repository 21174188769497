/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
._1a-oRF4rg_U9RILL9NrctE {
  height: 100%;
  background: url('https://www.static-immobilienscout24.de/statpic/agent-search-web/b72fced4b0a780df3fc490c6dc925238_hero.jpg') #333333 center;
  background-size: cover;
}
._6lE2y9h1LWU6Sk1I9USId {
  max-width: 1170px;
}
._1j73jR7PvhPfsvEt70F-F {
  padding-top: 16px;
  margin-bottom: 0;
}
@media (max-width: 668px) {
  ._1j73jR7PvhPfsvEt70F-F {
    padding-top: 10px;
    margin-bottom: 0;
  }
}
._3_bumO82e4GJnokN5oD2lu {
  margin-top: 62px;
}
._2VLwpi0OtOEWsZWqwW5AFh {
  height: 100%;
  padding: 8px;
  background-color: #333333;
  color: #e0e0e0;
}
._2VLwpi0OtOEWsZWqwW5AFh span {
  margin-left: 16px;
  line-height: 20px;
}
@media (min-width: 669px) {
  ._2VLwpi0OtOEWsZWqwW5AFh span:first-child {
    margin-left: 0;
  }
}
@media (max-width: 668px) {
  ._2VLwpi0OtOEWsZWqwW5AFh {
    padding: 16px 0;
  }
  ._2VLwpi0OtOEWsZWqwW5AFh span {
    display: block;
    text-align: left;
  }
}
._2VLwpi0OtOEWsZWqwW5AFh i {
  font-size: 1.2rem;
  font-weight: bold;
  color: #00ffd0;
  margin-right: 6px;
}
