/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
._1lvXkfps3Xg_5_A7PwjO7J {
  width: 100%;
}
._2a5BriXgeFgwKk7d4GLhaO {
  margin: 0;
  border: none;
  border-top: 2px solid #ebebeb;
}
._2DJYM5wbzjl5kSO8YMDOwn {
  padding: 0 24px;
}
@media (max-width: 668px) {
  ._2DJYM5wbzjl5kSO8YMDOwn {
    padding: 0;
  }
}
._1N4johJw4zBDJj7nHTtV1E {
  padding: 16px 32px 32px;
}
@media (min-width: 669px) and (max-width: 1013px) {
  ._1N4johJw4zBDJj7nHTtV1E {
    padding: 16px 24px 24px;
  }
}
@media (max-width: 668px) {
  ._1N4johJw4zBDJj7nHTtV1E {
    padding: 16px;
  }
}
